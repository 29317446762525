import { useState } from "react";

const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ01234567890";

interface Props {
  text: string;
}

const HackerTextOnHover = ({ text }: Props) => {
  const upperCaseText = text.toUpperCase();
  const [displayedText, setDisplayedText] = useState(upperCaseText);
  const [intervalId, setIntervalId] = useState<number | null>(null);

  function onMouseOver(event: React.MouseEvent<HTMLHeadingElement>) {
    let iteration = 0;

    clearInterval(intervalId ?? undefined);

    const newIntervalId = setInterval(() => {
      const target = event.target as HTMLHeadingElement;
      if (!target) {
        clearInterval(newIntervalId);
        return;
      }

      const newText = target.innerText
        .split("")
        .map((letter, index) => {
          if (index < iteration) {
            return target.dataset.value?.[index] ?? "";
          }

          return letters[Math.floor(Math.random() * 36)];
        })
        .join("");

      setDisplayedText(newText);
      if (
        target.dataset.value?.length &&
        (iteration >= target.dataset.value?.length ?? 0)
      ) {
        clearInterval(newIntervalId);
      }

      iteration += 1 / 3;
    }, 30);

    setIntervalId(newIntervalId as unknown as number | null);
  }

  return (
    <h1
      className="hacker-text"
      onMouseOver={onMouseOver}
      data-value={upperCaseText}
    >
      {displayedText}
    </h1>
  );
};

export default HackerTextOnHover;
