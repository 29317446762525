import React, { useState, FormEvent } from "react";
import { validateEmail } from "../util/HelperFunctions";

interface FormState {
  name: string;
  email: string;
  message: string;
}

const ContactForm = () => {
  const [formState, setFormState] = useState<FormState>({
    name: "",
    email: "",
    message: "",
  });
  const [selectedBudget, setSelectedBudget] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState("");

  const { name, email, message } = formState;

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (e.target.name === "Email") {
      const isValid = validateEmail(e.target.value);

      if (!isValid) {
        setErrorMessage("Please enter a valid email");
      } else {
        setErrorMessage("");
      }
    } else {
      if (!e.target.value.length) {
        setErrorMessage(`${e.target.name} is required.`);
      } else {
        setErrorMessage("");
      }
    }

    if (!errorMessage) {
      setFormState({ ...formState, [e.target.name]: e.target.value });
    }
  }

  function handleSubmit(e: FormEvent<HTMLButtonElement>) {
    e.preventDefault();
    // reset values and display success message
    setFormState({ name: "", email: "", message: "" });
    alert(
      "Thank you for reaching out! We'll get back to your request shortly."
    );
    // redirect to home page
    window.location.href = "/";
  }

  // Add a new handleChange function for the select element
  function handleSelectChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedBudget(e.target.value);
  }

  return (
    <div className="contact-container">
      <div className="contact-title">
        <h2>Contact Us</h2>
        <hr></hr>
      </div>
      <form
        className="justify-content-center"
        id="contact-form"
        method="post"
        action="https://forms.un-static.com/forms/ba5815f9af34cafc9babf65d15f5333f87cbe6bc"
      >
        <div className="contact-input">
          <label htmlFor="Name" style={{ fontSize: "1.2rem" }}>
            Name
          </label>
          <input
            className="form-control"
            type="text"
            name="Name"
            defaultValue={name}
            onBlur={handleChange}
          />
        </div>
        <div className="contact-input">
          <label htmlFor="email" style={{ fontSize: "1.2rem" }}>
            Email Address
          </label>
          <input
            className="form-control"
            type="email"
            name="Email"
            defaultValue={email}
            onBlur={handleChange}
          />
        </div>
        <div className="contact-input">
          <label htmlFor="Budget" style={{ fontSize: "1.2rem" }}>
            Project Budget
          </label>
          <select
            className="form-control"
            name="Budget"
            value={selectedBudget}
            onChange={handleSelectChange}
          >
            <option value="">Select a budget range</option>
            <option value="<$10,000">Less than $10,000</option>
            <option value="$10,000-$25,000">$10,000 - $25,000</option>
            <option value="$25,000-$100,000">$25,000 - $100,000</option>
            <option value="$100,000+">Greater than $100,000</option>
          </select>
        </div>
        <div className="contact-input">
          <label htmlFor="Message" style={{ fontSize: "1.2rem" }}>
            Message:
          </label>
          <textarea
            className="form-control"
            name="Message"
            defaultValue={message}
            onBlur={handleChange}
            rows={7}
          />
        </div>
        {errorMessage && (
          <div>
            <p className="error-text">{errorMessage}</p>
          </div>
        )}

        <div className="contact-input">
          <button
            data-testid="button"
            className="btn btn-outline-dark "
            type="submit"
            style={{ fontSize: "1.2rem" }}
            onSubmit={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
