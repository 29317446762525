import { useNavigate } from "react-router-dom";

type NavLinkProps = {
  href: string;
  children: any;
  onClick?: () => void;
};

function NavLink({ href, children, onClick }: NavLinkProps) {
  const navigate = useNavigate();

  function handleClick(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    navigate(href);

    if (onClick) {
      onClick();
    }
  }

  return <div onClick={handleClick}>{children}</div>;
}

export default NavLink;
