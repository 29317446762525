import { useNavigate } from "react-router-dom";
import HackerTextOnHover from "../util/HackerTextOnHover";
import HomeCanvas from "./HomeCanvas";
import { ReactComponent as Logo } from "../images/logo.svg";

function Landing() {
  const navigate = useNavigate();

  function handleContactClick() {
    navigate("/contact");
  }
  return (
    <div className="landing-container">
      <HomeCanvas />
      <div className="pitch">
        <div className="landing-title">
          <HackerTextOnHover text="ENCOD" />
          <Logo className="title-image" />
        </div>
        <div className="glass-card landing-pitch">
          <p>
            Revolutionize creative production with cutting-edge technology,
            automation, and AI-driven insights. Contact us today to streamline
            workflows, enhance communication, and reduce costs. Let's shape the
            future of entertainment together.
          </p>
        </div>

        <button className="landing-button" onClick={handleContactClick}>
          Contact Us
        </button>
      </div>
      <div className="landing-side-bar">
        <div />
      </div>
    </div>
  );
}
export default Landing;
