import Spline from "@splinetool/react-spline";
function HomeSpline() {
  function disablePan() {
    const spline = document.querySelector(".spline");
    if (spline) {
      spline.addEventListener("mousedown", (e) => {
        e.preventDefault();
      });
    }
  }

  return (
    <Spline
      className="spline"
      scene="https://prod.spline.design/4qFgk1peqQIyMaeT/scene.splinecode"
      onLoad={disablePan}
    />
  );
}
export default HomeSpline;
